import { scroll_to_top_icon } from "assets/svgs/customSvgs";
import { useEffect, useState } from "react";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { ScrollToTopStyle } from "./scrollToTopStyle";

import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
const CmsImage = dynamic(() => import("components/cms/cmsImage/cmsImage"));

export default function ScrollToTop() {
  const { t: tPublic } = useTranslation("public");
  const [scrollPosition, setScrollPosition] = useState(false);

  function scroll() {
    console.log("execute scroll to top");
    document
      .getElementById("layout")
      .classList.add("temporarilyDisableAnimation");
    document.getElementById("scroll-to-top-anchor").scrollIntoView();
    window.scrollTo({
      behavior: globalSettings.scrolltotop?.scrollEffect || "smooth",
      top: 0,
    });
  }

  function scrollHandler() {
    setScrollPosition(window.pageYOffset);
  }
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    setScrollPosition(window.pageYOffset > 0 ? true : false);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <>
      {globalSettings.scrolltotop?.enabled && (
        <>
          <div
            className={`${
              scrollPosition > 0 ? "" : "hidden"
            } scroll-to-top-wrapper d-print-none`}
            onClick={scroll}
          >
            {globalSettings.scrolltotop?.icon ? (
              <CmsImage
                // globalSetting-Image
                img={globalSettings.scrolltotop.icon.url}
                alt={tPublic("scrollTop-altText")}
                title={tPublic("scrollTop-title")}
                height="100%"
                objectFit="contain"
                maxWidth={globalSettings.scrolltotop.width}
              />
            ) : (
              scroll_to_top_icon(globalSettings.scrolltotop.arrowColor)
            )}
          </div>
          <ScrollToTopStyle />
        </>
      )}
    </>
  );
}
