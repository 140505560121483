import { globalSettings } from "services/globalSettings/globalSettingsService";

export const ScrollToTopStyle = (overrides) => {
  return (
    <style jsx global>{`
      .scroll-to-top-wrapper {
        display: block;
        position: fixed;
        bottom: 30px;
        right: 50px;
        z-index: 1000;
        width: ${globalSettings.scrolltotop.widthMobile}px;
        height: ${globalSettings.scrolltotop.heightMobile}px;
        font-size: 18px;
        background-color: ${globalSettings.scrolltotop.backgroundColor};
        padding: 0px;
        border-radius: ${globalSettings.scrolltotop.borderRadiusMobile}px;
        border: 1px solid ${globalSettings.scrolltotop.backgroundColor};
        &.hidden {
          display: none;
        }
        :hover {
          cursor: pointer;
        }
      }

      /* Container Tablet */
      @media (min-width: ${globalSettings.responsive.breakpointmobile}px) {
        .scroll-to-top-wrapper {
          width: ${globalSettings.scrolltotop.widthTablet}px;
          height: ${globalSettings.scrolltotop.heightTablet}px;
          padding: 3px;
          border-radius: ${globalSettings.scrolltotop.borderRadiusTablet}px;
        }
      }

      /* Container Desktop */
      @media (min-width: ${globalSettings.responsive.breakpointdesktop}px) {
        .scroll-to-top-wrapper {
          width: ${globalSettings.scrolltotop.widthDesktop}px;
          height: ${globalSettings.scrolltotop.heightDesktop}px;
          padding: 6px;
          border-radius: ${globalSettings.scrolltotop.borderRadiusDesktop}px;
        }
      }

      /* Container 2k Desktop */
      @media (min-width: ${globalSettings.responsive.breakpoint2k}px) {
        .scroll-to-top-wrapper {
          width: ${globalSettings.scrolltotop.width2k}px;
          height: ${globalSettings.scrolltotop.height2k}px;
          padding: 8px;
          border-radius: ${globalSettings.scrolltotop.borderRadius2k}px;
        }
      }
    `}</style>
  );
};
